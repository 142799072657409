<template>
  <b-card title="Danh sách banner">
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: title -->
        <span v-if="props.column.field === 'title'" class="text-nowrap"
        >
          <router-link :to="{name: 'manage-banner-edit', params: {id: props.row.id}}">
            <span class="text-nowrap">{{ props.row.title }}</span>
          </router-link>
        </span>

        <!-- Column: url -->
        <span v-else-if="props.column.field === 'url'" class="text-nowrap">
          <a :href="props.row.url">{{ props.row.url.substring(0, Math.min(50, props.row.url.length)) }}</a>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mr-2"
            type="submit"
            variant="danger"
            @click="deleteConfirmation(props.row.id, props.index)"
          >Xóa</b-button>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {BCard, BButton} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import Ripple from "vue-ripple-directive";
import ToastificationContent from "../../../@core/components/toastification/ToastificationContent";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    VueGoodTable,
    BButton
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Tiêu đề',
          field: 'title',
        },
        {
          label: 'Link',
          field: 'url',
        },
        {
          label: 'Ảnh banner',
          field: 'banner',
        },
        {
          label: 'Cập nhật',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: ''
    }
  },
  computed: {},
  methods: {
    deleteNksx(id, idx) {
      this.$http.delete(`/banners/${id}`)
        .then(response => {
          console.log(response.data)
          this.rows.splice(idx, 1)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              icon: 'BellIcon',
              text: 'Cập nhật thành công👋',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              icon: 'BellIcon',
              text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
              variant: 'danger',
            },
          })
        })
    },
    deleteConfirmation(id, idx){
      this.$bvModal.msgBoxConfirm('Hành động này không thể khôi phục. Vẫn xóa?', {
        title: 'Xác nhận muốn xóa dữ liệu',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Xóa',
        cancelTitle: 'Hủy',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true
      })
        .then(value => {
          if (value) {
            this.deleteNksx(id, idx)
          }
        })
        .catch(err => {
          console.log(err, id)
        })
    }
  },
  created() {
    this.$http.get('/banners')
      .then(res => {
        this.rows = res.data.data
      })
  },
}
</script>
