<template>
  <b-row>
    <b-col cols="12">
      <banner-table-basic></banner-table-basic>
    </b-col>
  </b-row>
</template>

<script>
import BannerTableBasic from "../../../table/vue-good-table/BannerTableBasic";
import {BCol, BRow} from 'bootstrap-vue'

export default {
  name: "Banner",
  components: {BannerTableBasic, BRow, BCol}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>